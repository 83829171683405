<template>
  <div id="home">
    <van-nav-bar
        title="余额明细"
        :left-arrow="true"
        @click-left="goback('/recharge')"
        fixed
    />
    <div class="login wallets">
      <van-list class="fundlogslist" style="padding-top: 3px;"
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getfundlogslist">
        <van-cell class="auditcard"  v-for="(item,idx) in fundlogslist" :key="idx">
          <template #icon>
            <span class="icon" v-if="item.opt_type == 0">+</span>
            <span class="icon" v-else>-</span>
          </template>
          <div class="title">
            <div class="sn">{{ item.created_at }}</div>
            <div class="amount">{{ item.amount }}</div>
          </div>
          <div class="info">
            <div class="time">{{ item.remarks }}</div>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import {onMounted, reactive,ref, toRefs} from 'vue';
import {httpget, httpput} from "@/libs/api";
import {Dialog,Toast} from "vant";

export default {
  name: 'fundlogs',
  setup() {
    const state = reactive({
      loading:false,
      gopayloading:false,
      commentloading:false,
      finished:false,
      active:1,
      fundlogslist:[],
      searchinfo:{
        page:0,
      },
    })
    const getfundlogslist = async () =>{
      state.loading = true;
      if(!state.finished){
        state.searchinfo.page++;
      }
      let res = await httpget('/api/recharge/fundlogs',state.searchinfo);
      if(res.code===0){
        state.loading = false;
        if(state.searchinfo.page==1){
          state.fundlogslist = [];
        }
        if(res.data.data.length>0){
          res.data.data.forEach((task)=>{
            state.fundlogslist.push(task);
          })
        }
        if(res.data.data.length<20){
          state.finished = true;
        }
      }else{
        state.loading = false;
        state.finished = true;
        return ;
      }
    }
    onMounted(() => {
      getfundlogslist();
    })

    return {
      ...toRefs(state),
      getfundlogslist
    };
  },
}
</script>
